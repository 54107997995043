<template>
  <div
    class="flex flex-col lg:flex-row-reverse space-y-2 lg:space-y-0 justify-between"
  >
    <right-sidebar class="w-full lg:w-1/5" />
    <div class="w-full lg:w-4/5 lg:mr-1">
      <div class="border rounded bg-white p-2">
        <data-table
          v-model="reviewTemplates"
          :show-filters="false"
          :permission="permission"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'employee-performance-template',
  props: [],
  data() {
    return {
      reviewTemplates: {
        title: 'Vorlagen',
        resource: 'performance-evaluation/template',
        columns: [
          {
            label: 'Vorlage',
            field: ['name'],
            type: 'text',
            headerClass: 'class-in-header second-class',
            headerAlign: 'left',
            width: '20',
            postfix: '',
          },
          {
            label: 'Beschreibung',
            field: ['description'],
            headerAlign: 'left',
            width: '40',
            type: 'text',
            postfix: '',
          },
          {
            label: 'erstellt von',
            field: [],
            headerAlign: 'left',
            width: '20',
            type: 'text',
          },
          { label: '', field: '', type: 'actions', width: '10' },
        ],
        rows: [],
        actions: {
          index: null,
          list: ['edit', 'delete'],
          callback: state => {
            this.$bus.$emit('show-modal', {
              show: true,
              title:
                state.type === 'delete'
                  ? 'Mitarbeitergespräch - Vorlage löschen'
                  : 'Mitarbeitergespräch - Vorlage bearbeiten',
              component:
                state.type === 'delete'
                  ? 'remove-dialog'
                  : 'new-performance-review-template',
              request: state.type,
              message: `Möchten Sie wirklich die Vorlage ${state.payload.name} löschen?`,
              // message: `Möchten Sie wirklich den Performance Review Template  entfernen?`,
              resource: `/performance-evaluation/template/${state.payload.id}/`,
              data: state.payload,
              maxWidth: 'w-full md:w-1/2',
              event: 'reload-template-data-table',
              type: state.type,
              dialogType: state.type !== 'delete' ? 'wizard' : 'simple',
            })
          },
        },
      },
      permission: {
        module: 'Personal',
        subModule: 'Mitarbeitergespräche',
        childModule: 'Mitarbeitergespräche Template',
        operation: 'edit',
        options: ['All'],
      },
      breadcrumbs: {
        title: 'Vorlage erstellen',
        actions: {
          title: this.can(
            'Personal',
            'Mitarbeitergespräche',
            'Mitarbeitergespräche Template',
            'view',
            ['All']
          )
            ? 'Vorlage erstellen'
            : null,
          component: 'new-performance-review-template',
          resource: '/performance-evaluation/template/',
          maxWidth: 'w-full md:w-1/2',
          event: 'reload-template-data-table',
          dialogType: 'wizard',
        },
        permission: {
          module: 'Personal',
          subModule: 'Mitarbeitergespräche',
          childModule: 'Mitarbeitergespräche Template',
          operation: 'edit',
          options: ['All'],
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$emit('breadcrumbs', this.breadcrumbs)
    },
  },
}
</script>

<style></style>
